import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import './AdminPayments.css';
import axios from "axios";
import { message } from "antd";

const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalPayments, setTotalPayments] = useState(0);  // Total payments count
  const [currentPage, setCurrentPage] = useState(1);  // Current page
  const [paymentsPerPage, setPaymentsPerPage] = useState(25);  // Payments per page
  const [searchQuery, setSearchQuery] = useState("");  // Optional search query

  const perPageOptions = [25, 50, 100, 250, 500]; // Available per page options

  // Pagination logic
  const totalPages = Math.ceil(totalPayments / paymentsPerPage);

  const handlePageChange = (page) => setCurrentPage(page);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const generatePageNumbers = () => {
    const range = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (currentPage < 3) {
        range.push(1, 2);
        range.push("...");
        range.push(totalPages);
      } else if (currentPage > totalPages - 3) {
        range.push(1);
        range.push("...");
        range.push(totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        range.push(1);
        range.push("...");
        range.push(currentPage - 1, currentPage, currentPage + 1);
        range.push("...");
        range.push(totalPages);
      }
    }
    return range;
  };

  const pageNumbers = generatePageNumbers();

  // Fetch all payments
  const getAllPayments = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/api/payment/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          page: currentPage,
          limit: paymentsPerPage,
          searchQuery,
        },
      });

      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setTotalPayments(res.data.totalPayments);  // Set total count of payments
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch payments.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPayments(); // Initial load
  }, [currentPage, paymentsPerPage, searchQuery]); // Dependency array includes pagination and search query

  return (
    <AdminLayout>
      <div className="admin-container" style={{ color: "white" }}>
        <h3>Payments (Total: {totalPayments})</h3>
        <div className="filters">
          <div>
            <input
              type="search"
              placeholder="Search by email or txn ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={() => setCurrentPage(1)} className="btn btn-primary ms-2">
              Search
            </button>
          </div>
          <div>
            <label htmlFor="perPage">Payments per page: </label>
            <select
              id="perPage"
              value={paymentsPerPage}
              onChange={(e) => {
                setPaymentsPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when changing per page option
              }}
            >
              {perPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
  
          {/* Pagination Controls */}
          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1} className="btn-pagination">
              Previous
            </button>
            {pageNumbers.map((num, index) => (
              num === "..." ? (
                <span key={index} className="dots">...</span>
              ) : (
                <button
                  key={index}
                  onClick={() => handlePageChange(num)}
                  className={`btn-pagination ${currentPage === num ? "active" : ""}`}
                >
                  {num}
                </button>
              )
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages} className="btn-pagination">
              Next
            </button>
          </div>
        </div>
  
        {/* Table Container for Scrollable Table */}
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Client Txn ID</th>
                <th>Date</th>
              </tr>
            </thead>
            {loading ? (
              <div className="loader-container text-center">
                <span className="loader"></span>
              </div>
            ) : (
              <tbody>
                {payments &&
                  payments.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>Rs. {item?.price}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.client_txn_id}</td>
                      <td>
                        {new Date(item?.payDate).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </AdminLayout>
  );
  
};

export default AdminPayments;
