import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminDashboard.css";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { LoadingDots } from "./components/LoadingDots";


const AdminDashboard = () => {
  const navigate = useNavigate();

  // States for data
  // const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [queries, setQueries] = useState([]);
  const [summary, setSummary] = useState([]);

  // Loading states for each section
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [queriesLoading, setQueriesLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);

  // Fetch all queries
  const fetchQueries = async () => {
    setQueriesLoading(true);
    try {
      const res = await axios.get("/api/admin/get-all-queries", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) setQueries(res.data.data);
    } catch (error) {
      message.error("Failed to load queries.");
    } finally {
      setQueriesLoading(false);
    }
  };

  // Fetch all orders
  // const fetchOrders = async () => {
  //   setOrdersLoading(true);
  //   try {
  //     const res = await axios.get("/api/admin/admin-get-all-orders", {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     if (res.data.success) setOrders(res.data.data);
  //   } catch (error) {
  //     message.error("Failed to load orders.");
  //   } finally {
  //     setOrdersLoading(false);
  //   }
  // };

  // Fetch Dashboard Summary

  const fetchSummary = async () => {
    setSummaryLoading(true);
    try {
      const res = await axios.get("/api/admin/admin-get-summary", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) {
        console.log(res.data.data);

        setSummary(res.data.data);
      }
    } catch (error) {
      message.error("Failed to load orders.");
    } finally {
      setSummaryLoading(false);
    }
  };

  // Fetch all products
  const fetchProducts = async () => {
    setProductsLoading(true);
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) setProducts(res.data.data.reverse());
    } catch (error) {
      message.error("Failed to load products.");
    } finally {
      setProductsLoading(false);
    }
  };

  // Compute total sales
  // const totalSum = orders.reduce((acc, order) => acc + parseFloat(order.price || 0), 0);
  // const formattedTotal =
  //   totalSum >= 1000 ? `${(totalSum / 1000).toFixed(1)}k` : totalSum.toString();

  // Fetch data on component mount
  useEffect(() => {
    fetchSummary();
    // fetchOrders();
    fetchQueries();
    fetchProducts();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0" style={{ color: "white" }}>Dashboard</h3>
      </div>
      <hr />
      <div className="admin-dashboard-container">
        {/* Total Orders */}
        <div className="dash-card" onClick={() => navigate("/admin-orders")}>
          <div className="count">
            <h1 className="m-0">{summaryLoading ? <LoadingDots /> : summary.ordersCount}</h1>
            <span className="text-muted">Total Orders</span>
          </div>
          <PointOfSaleIcon className="icon" />
        </div>


        {/* Total Products */}
        <div className="dash-card" onClick={() => navigate("/admin-products")}>
          <div className="count">
            <h1 className="m-0">{productsLoading ? <LoadingDots /> : products.length}</h1>
            <span className="text-muted">Total Products</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>

        {/* Total Sales */}
        <div className="dash-card" onClick={() => navigate("/admin-payments")}>
          <div className="count">
            <h1 className="m-0">{summaryLoading ? <LoadingDots /> : summary.totalRevenue}</h1>
            <span className="text-muted">Total Sales</span>
          </div>
          <MonetizationOnIcon className="icon" />
        </div>

        {/* Pending Queries */}
        <div className="dash-card" onClick={() => navigate("/admin-queries")}>
          <div className="count">
            <h1 className="m-0">
              {queriesLoading
                ? "..."
                : queries.filter((item) => item.status === "pending").length}
            </h1>
            <span className="text-muted">Pending Queries</span>
          </div>
          <HelpIcon className="icon" />
        </div>
      </div>

      {/* Recent Sections */}
      <div className="admin-recent-things">
        {/* Recent Orders */}
        {/* <div className="recent-orders">
          <h5>Recent Orders</h5>
          <hr />

          {ordersLoading ? <LoadingDots /> : 

          <table className="table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Email</th>
                <th>Total</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {orders.slice(0, 5).map((item) => (
                <tr key={item.orderId}>
                  <td>{item.orderId}</td>
                  <td>{item.customer_email}</td>
                  <td>{item.price}</td>
                  <td>
                    {new Date(item.createdAt).toLocaleDateString("default", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                  <td>
                    <RemoveRedEyeIcon
                      onClick={() =>
                        navigate(`/admin-view-order/${item.client_txn_id}`)
                      }
                      className="text-success icon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
}
        </div> */}

        {/* Recent Queries */}
        <div className="recent-queries">
          <h5>Recent Queries</h5>
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {queries
                .filter((query) => query.status === "pending")
                .slice(0, 5)
                .map((query) => (
                  <tr key={query.id}>
                    <td data-label="Name">{query?.name}</td>
                    <td data-label="Email">{query?.email}</td>
                    <td data-label="Mobile">{query?.mobile}</td>
                    <td data-label="Message">{query.msg.slice(0, 10)}...</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
