import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { LoadingDots } from "./components/LoadingDots";
import "./AdminOrder.css";

const AdminOrder = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(25);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");

  const totalPages = Math.ceil(totalOrders / ordersPerPage);

  const handlePageChange = (page) => setCurrentPage(page);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const generatePageNumbers = () => {
    const range = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (currentPage <= 3) {
        range.push(1, 2, 3, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        range.push(1, "...", totalPages - 2, totalPages - 1, totalPages);
      } else {
        range.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...");
      }
    }
    return range;
  };

  const pageNumbers = generatePageNumbers();

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/api/admin/admin-get-all-orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          page: currentPage,
          limit: ordersPerPage,
          startDate,
          endDate,
          status,
          email,
        },
      });
      if (res.data.success) {
        setOrders(res.data.data);
        setTotalOrders(res.data.totalOrders);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch orders.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [currentPage, ordersPerPage, startDate, endDate, status, email]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <AdminLayout>
      <div className="admin-orders-container">
        <div className="order-page-title">
          <h3 className="m-0">All Orders</h3>
        </div>
        <hr />
        {/* Date Filters */}
        <div className="filters">
          {/* Date Filters */}
          <div className="date-filters">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
            <button onClick={getAllOrders}>Filter</button>
          </div>

          {/* Status and Email Filters */}
          <div className="additional-filters">
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">Status</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="success">Success</option>
              <option value="cancelled">Cancelled</option>
              <option value="refunded">Refunded</option>
              <option value="failed">Failed</option>
            </select>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <button onClick={getAllOrders}>Apply Filters</button>
          </div>

          {/* Rows Per Page */}
          <div className="rows-per-page">
            <label htmlFor="ordersPerPage">Rows per page:</label>
            <select
              id="ordersPerPage"
              value={ordersPerPage}
              onChange={(e) => {
                setOrdersPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </select>
          </div>



          {/* Pagination */}
          <div className="pagination">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className="btn-pagination"
            >
              Previous
            </button>
            {pageNumbers.map((num, index) =>
              num === "..." ? (
                <span key={index} className="dots">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  onClick={() => handlePageChange(num)}
                  className={`btn-pagination ${currentPage === num ? "active" : ""
                    }`}
                >
                  {num}
                </button>
              )
            )}
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className="btn-pagination"
            >
              Next
            </button>
          </div>
        </div>


        {/* Filtered Orders Count */}
        <div className="filtered-orders-count mb-3">
          <strong>Total Filtered Orders: {totalOrders}</strong>
        </div>

        <div className="table-container">
          {/* Orders Table */}
          <table className="table order-table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Order Type</th>
                <th>Email</th>
                <th>Total</th>
                <th>Status</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            {loading ? (
              <LoadingDots />
            ) : (
              <tbody>
                {orders.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.orderId}</td>
                    <td>
                      {item?.api === "no" ? "Manual Orders" : "API Orders"}
                    </td>
                    <td >{item?.customer_email}</td>
                    <td >Rs. {item?.price}</td>
                    <td>{item?.status}</td>
                    <td>
                      {item?.createdAt ? formatDate(item.createdAt) : "N/A"}
                    </td>
                    <td
                  
                        onClick={() => navigate(`/admin-view-order/${item?.orderId}`)}
                        className="text-primary">
                          view
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminOrder;
