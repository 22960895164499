import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import "./TrackOrder.css";
import axios from "axios";
import { message } from "antd";

const TrackOrder = () => {
  const [tabs, setTabs] = useState(0);
  const [form, setForm] = useState({ orderId: "", email: "" });
  const [orderData, setOrderData] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/order/track-order", {
        client_txn_id: form?.orderId,
        email: form?.email,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="container-fluid bg-white register-container">
        <div className="row text-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
            <form className="register-form" onSubmit={handleSubmit}>
              <h1 className="track-order-title">Track Your Order</h1>
              <div className="tabs">
                <div
                  onClick={() => setTabs(0)}
                  className={`tab-button ${tabs === 0 && "active"}`}
                >
                  Email
                </div>
                <div
                  onClick={() => setTabs(1)}
                  className={`tab-button ${tabs === 1 && "active"}`}
                >
                  Order ID
                </div>
              </div>
              {tabs === 0 && (
                <div className="form-fields mb-3">
                  <input
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                  />
                </div>
              )}
              {tabs === 1 && (
                <div className="form-fields mb-3">
                  <input
                    onChange={(e) =>
                      setForm({ ...form, orderId: e.target.value })
                    }
                    value={form?.orderId}
                    name="orderId"
                    type="text"
                    className="form-control"
                    placeholder="Enter Order ID"
                  />
                </div>
              )}
              <button type="submit" className="track-btn">
                Track Order
              </button>
            </form>
          </div>
        </div>

        <hr className="my-5" />
        <div className="container track-orders-display">
          {orderData?.length > 0 ? (
            <div className="track-order-key-value">
              {orderData?.map((item, index) => (
                <div className="order-card" key={index}>
                  <div className="order-item">
                    <span className="order-key">Order ID:</span>
                    <span className="order-value">{item?.orderId}</span>
                  </div>
                  <div className="order-item">
                    <span className="order-key">Product:</span>
                    <span className="order-value">{item?.p_info}</span>
                  </div>
                  <div className="order-item">
                    <span className="order-key">Total:</span>
                    <span className="order-value">₹{item?.price}</span>
                  </div>
                  <div className="order-item">
                    <span className="order-key">User ID:</span>
                    <span className="order-value">{item?.userId}</span>
                  </div>
                  <div className="order-item">
                    <span className="order-key">Status:</span>
                    <span className="order-value">{item?.status}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center no-result">No result found</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TrackOrder;
