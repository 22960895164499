import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HeroSlider.css";

const HeroSlider = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]); // State to store banners
  const [loading, setLoading] = useState(false); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  const getBanners = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/user/getcarousel");
      setBanners(response.data.data || []); // Set fetched banners to state
    } catch (error) {
      console.error("Error fetching banners:", error);
      setError(error.response?.data?.message || "Failed to fetch banners");
    } finally {
      setLoading(false); // Stop loading regardless of success or error
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,           // Enable auto scroll
    autoplaySpeed: 3000,      // Interval between scrolls
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <div className="hero-slider-container">
      {loading && <p>Loading banners...</p>}
      {error && <p className="error">{error}</p>}
      {banners.length > 0 ? (
        <Slider {...sliderSettings}>
          {banners.map((banner) => (
            <a href={banner.redirectUrl} key={banner._id}>
              <div className="banner-slide">
                <img src={banner.imageUrl} alt={banner.title} />
                {/* <div className="banner-content">
                  <h2>{banner.title}</h2>
                  <p>{banner.description}</p>
                </div> */}
              </div>
            </a>
          ))}
        </Slider>
      ) : (
        <p>No banners available</p>
      )}
    </div>
  );
};

export default HeroSlider;
