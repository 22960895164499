import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import defaultImg from "../../assets/defaultImg.jpg";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="games-container">
      <h1 className="text-center">Popular Games</h1>
      <div className="hrline"></div>
      <div className="game-product mt-5">
        {products?.map((item, index) => (
          <div
            key={index}
            className="game-card"
            onClick={() => navigate(`/product/${item?.name}`)}
          >
            <div className="card-img-container">
              <img
                src={`https://thoihenofficial.com/${item?.image}`}
                alt={item?.name || "Product Image"}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/150";
                }}
              />
            </div>
            <div className="card-content">
              <div className="game-discount">Discount</div>
              <h4 className="game-title">{item?.name}</h4>
              <button className="top-up-btn">TOP UP</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Games;
