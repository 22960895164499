import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import "./ViewOrder.css";

const ViewOrder = () => {
  const params = useParams();
  const [singleOrder, setSingleOrder] = useState(null);

  const getOrderById = async () => {
    try {
      const res = await axios.post(
        "/api/order/get-order-by-id",
        {
          orderId: params?.orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSingleOrder(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="view-order-summary">
          <span>
            Order #{singleOrder?.orderId} was placed on{" "}
            {singleOrder?.createdAt
              ? new Date(singleOrder.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : ""}{" "}
            and is currently <strong>{singleOrder?.status}</strong>.
          </span>
        </div>
        <div className="view-order-table-container">
          <table className="view-order-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Product">Order ID</td>
                <td data-label="Details">{singleOrder?.orderId}</td>
              </tr>
              <tr>
                <td data-label="Product">{singleOrder?.p_info}</td>
                <td data-label="Details">Rs. {singleOrder?.price}</td>
              </tr>
              <tr>
                <td data-label="Product">Status</td>
                <td data-label="Details">{singleOrder?.status}</td>
              </tr>
              <tr>
                <td data-label="Product">Amount</td>
                <td data-label="Details">{singleOrder?.amount}</td>
              </tr>
              <tr>
                <td data-label="Product">Player ID</td>
                <td data-label="Details">{singleOrder?.userId}</td>
              </tr>
              <tr>
                <td data-label="Product">Date</td>
                <td data-label="Details">
                  {singleOrder?.createdAt
                    ? new Date(singleOrder.createdAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default ViewOrder;
