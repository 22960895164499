import './LoadingDots.css';

export const LoadingDots = () => {
    return (
      <div className="loading-dots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
  };