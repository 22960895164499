import React from 'react'
import AdminLayout from "./components/AdminLayout";
import { useState } from 'react'
import './AdminCarousel.css'
import axios from 'axios'

export default function AdminCarousel() {

    const [message, setMessage] = useState('') // State to hold success or error message
    const [messageType, setMessageType] = useState('') // State to style the message (success/error)

    const initialState = {
        title: '',
        imageUrl: '',
        redirectUrl: '',
        description: '',
    }

    const [banner, setBanner] = useState(initialState)

    const handleSubmit = async () => {
        try {
            const res = await axios.post('/api/admin/postcarousel', banner)
            setMessage(res.data.message || 'Carousel item created successfully!')
            setMessageType('success') // Set message type to success
            setBanner(initialState) // Reset form after success
        } catch (error) {
            console.error(error)
            setMessage(error.response?.data?.message || 'Failed to create carousel item.')
            setMessageType('error') // Set message type to error
        }
    }

    return (
        <>
            <AdminLayout /> {/* Background content */}

            {/* AdminCarousel as an overlay */}
            <div className="admin-carousel-overlay">
                <div className="admin-carousel-container">

                    {/* Display success or error message */}
                    {message && (
                        <div className={`message ${messageType}`}>
                            {message}
                        </div>
                    )}

                    <div className='banner-title'>
                        <label htmlFor="title">Title</label>
                        <input
                            type='text'
                            id='title'
                            name='title'
                            value={banner.title}
                            onChange={(e) => setBanner({ ...banner, title: e.target.value })}
                        />
                    </div>

                    <div className='banner-imageUrl'>
                        <label htmlFor="imageUrl">Image URL</label>
                        <input
                            type='text'
                            id='imageUrl'
                            name='imageUrl'
                            value={banner.imageUrl}
                            onChange={(e) => setBanner({ ...banner, imageUrl: e.target.value })}
                        />
                    </div>

                    <div className='banner-redirectUrl'>
                        <label htmlFor="redirectUrl">Redirect URL</label>
                        <input
                            type='text'
                            id='redirectUrl'
                            name='redirectUrl'
                            value={banner.redirectUrl}
                            onChange={(e) => setBanner({ ...banner, redirectUrl: e.target.value })}
                        />
                    </div>

                    <div className='banner-description'>
                        <label htmlFor="description">Description</label>
                        <textarea
                            id='description'
                            name='description'
                            value={banner.description}
                            onChange={(e) => setBanner({ ...banner, description: e.target.value })}
                        />
                    </div>

                    <div className='banner-submit'>
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}
