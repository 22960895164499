import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import "./AdminUsers.css";
import { useNavigate } from "react-router-dom";

const AdminUsers = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(25); // Default users per page

  const perPageOptions = [10, 25, 50, 100]; // Available per page options

  // Pagination logic
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const handlePageChange = (page) => setCurrentPage(page);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const generatePageNumbers = () => {
    const range = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (currentPage < 3) {
        range.push(1, 2, 3, 4);
        range.push("...");
        range.push(totalPages);
      } else if (currentPage > totalPages - 3) {
        range.push(1);
        range.push("...");
        range.push(totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        range.push(1);
        range.push("...");
        range.push(currentPage - 1, currentPage, currentPage + 1);
        range.push("...");
        range.push(totalPages);
      }
    }
    return range;
  };

  const pageNumbers = generatePageNumbers();

  // Search filter
  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page when searching
    getAllUser();
  };

  const getAllUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          page: currentPage,
          limit: usersPerPage,
          searchQuery,
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setTotalUsers(res.data.totalUsers);  // Set the total users from response
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch users.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUser(); // Initial load
  }, [currentPage, usersPerPage, searchQuery]); // Dependency array includes pagination and search query

  const filterUser = filteredUsers && filteredUsers.length > 0 ? filteredUsers : allUser;

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Customers</h3>
        </div>
        <hr />

        {/* Display Total Users Count */}
        <div className="total-users-count">
          <h4>Total Users: {totalUsers}</h4>
        </div>

        <div className="table-container">
          <div className="tools">
            <div>
              <input
                className="mb-4"
                type="search"
                name="search"
                placeholder="Search by email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button onClick={handleSearch} className="btn btn-primary ms-2">
                Search
              </button>
            </div>
            <div className="per-page-selector">
              <label htmlFor="perPage">Users per page: </label>
              <select
                id="perPage"
                value={usersPerPage}
                onChange={(e) => {
                  setUsersPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page when changing per page option
                }}
              >
                {perPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <table className="table user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Balance</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5">Loading...</td>
                </tr>
              ) : (
                filterUser?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <small>{user?.fname}</small>
                    </td>
                    <td>
                      <small>{user?.email}</small>
                    </td>
                    <td>
                      <small>{user?.balance}</small>
                    </td>
                    <td>
                      <small>
                        {user?.created
                          ? new Date(user?.created).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )
                          : ""}
                      </small>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <EditIcon
                          onClick={() =>
                            navigate(`/admin-edit-user/${user?._id}`)
                          }
                          className="me-2 text-muted"
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>

          </table>

          {/* Pagination Controls */}
          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1} className="btn-pagination">
              Previous
            </button>
            {pageNumbers.map((num, index) => (
              num === "..." ? (
                <span key={index} className="dots">...</span>
              ) : (
                <button
                  key={index}
                  onClick={() => handlePageChange(num)}
                  className={`btn-pagination ${currentPage === num ? "active" : ""}`}
                >
                  {num}
                </button>
              )
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages} className="btn-pagination">
              Next
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
